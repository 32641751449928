import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'

import { Amplify } from 'aws-amplify'
import aws_exports from './aws-exports'
import { StrictMode } from 'react'

const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement!)

Amplify.configure(aws_exports)
root.render(
    <StrictMode>
        <App />
    </StrictMode>
    )
