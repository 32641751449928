import { Badge, Button, Card, Collection, Text, Divider, Grid, Heading, View, useAuthenticator, Flex, Message } from '@aws-amplify/ui-react'
import * as React from 'react'
import { MainSubTitle, MainTitle, ShortCenteredTextView, TitleSection } from '../components/Common'
import { MyTmpVpnClient } from '@mytmpvpn/mytmpvpn-client/client'
import { PeanutsPack } from '@mytmpvpn/mytmpvpn-common/models/peanuts'
import { useErrorContext } from '../ErrorContext'
import { useNavigate } from 'react-router-dom'
import { AmplifyUser } from '@aws-amplify/ui'



function maybeRedirectToUrl(url: any, user: AmplifyUser, setError: any) {
  user.getUserAttributes((err?, result?) => {
    if (err) {
      setError(err.message || JSON.stringify(err));
      return;
    }
    // If result is undefined error 
    if (result === undefined) {
      setError(`Can't get user attributes. Please logout and retry. If that does not work, create an issue`)
      return;
    }
    var sub = undefined;
    for (var i = 0; i < result.length; i++) {
      if (result[i].getName() === 'sub') {
        sub = result[i].getValue();
        break;
      }
    }
    if (sub === undefined) {
      setError(`Can't get user sub attributes. Please logout and retry. If that does not work, create an issue`)
      return;
    }
    console.log("sub", sub)
    var urlSuffix = `?client_reference_id=${encodeURIComponent(sub)}`
    console.log("urlsuffix", urlSuffix)
    urlSuffix += user.attributes?.email ? `&prefilled_email=${encodeURIComponent(user.attributes.email)}` : ''
    console.log("urlsuffix2", urlSuffix)
    // Check if the given URL has a coupon inside
    const coupon = new URLSearchParams(window.location.search).get('coupon')
    if (coupon) {
      urlSuffix += `&prefilled_promo_code=${coupon}`
    }
    console.log("urlsuffix3", urlSuffix)
    var fullUrl = new URL(urlSuffix, url).href

    window.open(fullUrl, '_blank')
  })
}


function PlanButton(props: any) {
  const navigate = useNavigate()
  const { error, setError } = useErrorContext()
  const { user } = useAuthenticator((context) => [context.user])

  const label = props.signed ? "Buy" : "Sign In"

  const action = props.signed ?
    () => maybeRedirectToUrl(props.url, user, setError) :
    () => navigate('/login')
  return (
    <Button onClick={action}>
      {label}
    </Button>
  )
}

export default function Pricing({ client }: { client: MyTmpVpnClient }) {
  const [peanutsPacks, setPeanutsPacks] = React.useState<PeanutsPack[]>([])
  const { route } = useAuthenticator((context) => [context.route])
  const { error, setError } = useErrorContext()

  React.useEffect(() => {
    client.listPeanutsPacks().then(packs => {
      setPeanutsPacks(packs)
    }).catch(e => setError(e))
  }, [])

  return (
    <Flex direction={'column'} alignItems={'center'}>

      <MainTitle>Pricing Plans</MainTitle>

      <Text>
        A vpn must be given some peanuts at creation time.<br />
        A vpn is terminated when all its given peanuts have been consumed.
      </Text>

      <Collection items={peanutsPacks}
        type="list"
        direction="row"
        alignSelf={'center'}
        gap="20px"
        wrap="nowrap"
      >
        {(pack, index) => (
          <Card
            key={pack.name}
            borderRadius="medium"
            // maxWidth="20rem"
            variation="outlined"
          >
            <Heading padding={"medium"} level={3}>{pack.name}</Heading>
            <Divider padding={"xs"} />
            <Heading padding={"medium"} level={4}>{pack.peanuts} Peanuts</Heading>
            <Badge>${pack.price} USD</Badge>
            <View textAlign={'justify'}>
              <ul>
                {pack.description.map((line) => (
                  <li key={line}>{line}</li>
                ))}
              </ul>
            </View>
            <PlanButton signed={route === 'authenticated'} url={pack.url} />
          </Card>
        )}

      </Collection>
      <Message
        variation='filled'
        colorTheme='info'
      >Please note that duration estimations above are informational only as they vary across regions.
      </Message>

    </Flex>
  )
}